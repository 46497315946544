import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AlumnosPlanClasesRouting } from "./alumnos-plan-clases-routing"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PuntajePlanClasesModule } from "@puntaje/puntaje/core"

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        PlanClasesModule,
        AlumnosPlanClasesRouting,
        AlumnosLayoutsModule,
        SharedModule,
        PuntajePlanClasesModule
    ]
})
export class AlumnosPlanClasesModule {}
