import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { ClasePlanClaseComponent, PlanClaseAlumnoComponent, PlanClasesDeAlumnosComponent } from "@puntaje/puntaje/core"

const routes: Routes = [
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseAlumnoComponent,
                canActivate: [AuthGuard],
                data: { titulo: "" }
            }
        ]
    },
    {
        path: "plan_clases/:id/sesiones/:sesionId",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard],
                data: { showVerLibro: false }
            }
        ]
    },
    {
        path: "tu_curso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClasesDeAlumnosComponent, canActivate: [AuthGuard] }]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlumnosPlanClasesRouting {}
