import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { StreamingModule as StreamingMod } from "@puntaje/puntaje/new-modules/streaming"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"

import { routing } from "./streaming.routing"
import { PuntajeStreamingModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [CommonModule, AlumnosLayoutsModule, StreamingMod, routing, BannersModule, PuntajeStreamingModule],
    exports: [],
    declarations: [],
    providers: []
})
export class AlumnosStreamingModule {}
