import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { UtilModule, ModelViewModule } from "@puntaje/shared/core"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
// Alumnos
import { LoggedLayoutComponent } from "./logged_layout/logged_layout.component"
import { EvaluationLayoutComponent } from "./evaluation_layout/evaluation_layout.component"
import { NavbarComponent } from "./navbar/navbar.component"
import { SidemenuComponent } from "./sidemenu/sidemenu.component"
import { TitlebarComponent, TitlebarComponentTags } from "./titlebar/titlebar.component"
import { FooterComponent } from "./footer/footer.component"
import { TopmenuComponent } from "./topmenu/topmenu.component"
import { PublicLayoutComponent } from "./public-layout/public-layout.component"
import { FooterPublicComponent } from "./footer-public/footer-public.component"
import { NavbarPublicComponent } from "./navbar-public/navbar-public.component"
// Utils
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { TooltipModule } from "ngx-bootstrap"
// Commons
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { TypeformWidgetsModule } from "@puntaje/puntaje/new-modules/typeform-widgets"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UtilModule,
        ModelViewModule,
        Angular2FontawesomeModule,
        TooltipModule,
        CommonsLayoutsModule,
        RouterModule,
        NotificacionesModule,
        SharedModule,
        TypeformWidgetsModule,
        CUILayoutsModule
    ],
    declarations: [
        LoggedLayoutComponent,
        NavbarComponent,
        SidemenuComponent,
        TitlebarComponent,
        TitlebarComponentTags,
        FooterComponent,
        EvaluationLayoutComponent,
        NavbarPublicComponent,
        TopmenuComponent,
        FooterComponent,
        PublicLayoutComponent,
        FooterPublicComponent
    ],
    exports: [
        LoggedLayoutComponent,
        NavbarComponent,
        SidemenuComponent,
        TitlebarComponent,
        TitlebarComponentTags,
        FooterComponent,
        EvaluationLayoutComponent,
        NavbarPublicComponent,
        CommonsLayoutsModule,
        TopmenuComponent,
        FooterComponent,
        PublicLayoutComponent,
        FooterPublicComponent
    ]
})
export class AlumnosLayoutsModule {
    static forRoot(): ModuleWithProviders<AlumnosLayoutsModule> {
        return {
            ngModule: AlumnosLayoutsModule,
            providers: []
        }
    }
}
