import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, HostListener } from "@angular/core"

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { SessionService } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "topmenu.component.html",
    selector: "topmenu",
    styleUrls: ["topmenu.component.scss"]
})
export class TopmenuComponent extends MenuComponent implements OnInit, OnDestroy {
    @Output() toggleTopmenuEvent = new EventEmitter()
    @ViewChild("topmenu") insideElement
    @HostListener("document:click", ["$event.target"]) public onClick(targetElement) {
        const clickedInside = this.insideElement.nativeElement.contains(targetElement)
        if (this.catchOutsideClick && !clickedInside) {
            this.toggleTopmenuEvent.emit()
        }
    }
    catchOutsideClick: boolean = false
    cursosActivos: any
    itemsMenu: any[]
    ITEMS_MENU = [
        /*  {
            name: "Evaluaciones SIMCE",
            route: "/simce",
            icon: "prueba",
            active: !this.sessionService.isFreeUser()
        }, */
        {
            name: "Pruebas curriculares",
            route: "/pruebas",
            icon: "guia",
            active: !this.sessionService.isFreeUser()
        },
        {
            name: "Estudiar",
            route: "/tu_curso",
            icon: "editar-prueba",
            active: true
        },
        // {
        //     name: "Videos y clases",
        //     route: "/streaming",
        //     icon: "video",
        //     active: true
        // },
        {
            name: "Biblioteca",
            route: "/materiales",
            icon: "libro2-o",
            queryParams: { asignatura_id: 1 },
            active: true
        },
        {
            name: "Recursos interactivos",
            route: "/recursos_interactivos",
            icon: "amor-y-paz",
            active: true
        }
    ]

    asignaturaId: number
    showSimce: boolean = true
    showPruebas: boolean = true
    @Input() config: any

    constructor(store: Store<State>, protected sessionService: SessionService, protected router: Router) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.asignaturaId = asignaturasByEvaluacionTipo["curricular"][0].id
        })
        setTimeout(() => {
            this.catchOutsideClick = true
        }, 300)

        this.itemsMenu = this.ITEMS_MENU
        const isFreeUser = this.sessionService.isFreeUser()

        this.showSimce = !isFreeUser
        this.showPruebas = !isFreeUser
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    onMenuClickOutside() {
        this.toggleTopmenuEvent.emit()
    }

    close() {
        this.toggleTopmenuEvent.emit()
    }
}
