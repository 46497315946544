import { SimpleSharedLayoutComponent } from "@puntaje/platforms/alcl"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { SharedLayoutComponent } from "@puntaje/platforms/alcl"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import {
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UsuarioPerfilAlumnoComponent,
    UsuariosEditComponent,
    CompletarRegistroComponent,
    RegisterConfirmationComponent,
    RegisterComponent,
    CompletarRegistroSocialComponent
} from "@puntaje/puntaje/core"
import { RegisterAprendolibreClComponent } from "@puntaje/puntaje/new-modules/usuarios"

export const routes: Routes = [
    //{ path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard] },
    /*{ path: 'usuarios/login', component: LoginComponent },*/
    {
        path: "usuarios/register",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: RegisterComponent,
                data: {
                    component: RegisterAprendolibreClComponent,
                    contentOverTitle: true
                }
            }
        ]
    },
    {
        path: "usuarios/registro",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: RegisterComponent,
                data: { component: RegisterAprendolibreClComponent, contentOverTitle: true }
            }
        ]
    },
    { path: "usuarios/confirmar_email/:token", component: RegisterConfirmationComponent },
    //{ path: 'usuarios/new', component: UsuariosNewComponent, canActivate: [AuthGuard] },
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [AuthGuard],
                data: { disableEditNombres: true, enableAsignaturaDefinida: false }
            }
        ]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: UsuariosEditComponent,
                canActivate: [AuthGuard],
                data: { 
                    disableEditNombres: true, 
                    enableAsignaturaDefinida: false, 
                    showPreferencia: false
                }
            }
        ]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: UsuarioPerfilAlumnoComponent, canActivate: [AuthGuard], data: { tab: "" } }]
    },
    {
        path: "usuarios/:id/completar_registro",
        component: SharedLayoutComponent,
        children: [{ path: "", component: CompletarRegistroComponent }]
    },
    {
        path: "usuarios/:id/completar_registro_social",
        component: SharedLayoutComponent,
        children: [{ path: "", component: CompletarRegistroSocialComponent }]
    },
    {
        path: "usuarios/passwords/forgot",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ForgotPasswordComponent }]
    },
    {
        path: "usuarios/passwords/reset/:reset_password_token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ResetPasswordComponent }]
    }
    /*{ path: 'usuarios/:id/logros', component: UsuarioComponent, canActivate: [AuthGuard], data: {tab: 'logros'} },*/
    //{ path: 'usuarios/:id/edit', component: UsuariosEditComponent, canActivate: [AuthGuard] },
    //{ path: 'usuarios/:id/edicion', component: EdicionComponent, canActivate: [AuthGuard] },
    //{ path: 'usuarios/:id/delete', component: UsuariosDeleteComponent, canActivate: [AuthGuard] }
]

export const routing = RouterModule.forChild(routes)
