import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { NoticiasModule } from "@puntaje/puntaje/new-modules/noticias"

import { routing } from "./alumnos_noticias.routing"
import { UtilModule, FormModule } from "@puntaje/shared/core"
import { AlumnosLayoutsModule } from "../layouts/index"
import { PuntajeNoticiasModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AlumnosLayoutsModule,
        UtilModule,
        NoticiasModule,
        routing,
        PuntajeNoticiasModule
    ],
    declarations: [],
    exports: []
})
export class AlumnosNoticiasModule {}
