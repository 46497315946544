<ll-toolnavbar>
    <materiales-toolnavbar></materiales-toolnavbar>
</ll-toolnavbar>
<ll-titulo>Biblioteca</ll-titulo>
<ll-subtitulo>{{ asignatura?.asignatura }}</ll-subtitulo>

<loading-layout #innerContent>
    <menu-asignaturas-horizontal
        [asignaturaId]="asignaturaId"
        [tipoAsignaturas]="'umayor'"
        [enableResponsive]="enableResponsive"
        (asignaturaChanged)="onChangeAsignatura($event)"
    ></menu-asignaturas-horizontal>
    <div (window:resize)="onResize($event)" [class.enableResponsive]="enableResponsive" class="biblioteca">
        <materiales-with-thumbnails
            *ngIf="asignaturaId"
            [tracking]="true"
            [asignaturaId]="asignaturaId"
            [cursoId]="cursoId"
            (onReady)="onReady($event)"
        ></materiales-with-thumbnails>
        <div class="sin-asignatura" *ngIf="!asignaturaId">
            <p>Selecciona una asignatura para ver el material disponible en nuestra biblioteca.</p>
        </div>
    </div>
</loading-layout>
