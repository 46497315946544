import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import {
    MaterialAppsComponent,
    MaterialComponent,
    MaterialesSaberComponent,
    MaterialesSIMCEComponent,
    MaterialesThumbnailsComponent,
    MaterialesV1Component,
    MaterialUDDComponent,
    RecursosInteractivosComponent
} from "@puntaje/puntaje/core"

export const materialesRoutes: Routes = [
    {
        path: "materiales",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialesV1Component, data: {enableProfesores: false}, canActivate: [AuthGuard] }]
    },
    {
        path: "materiales/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialComponent, canActivate: [AuthGuard], data: { enableDudas: false } }]
    },
    {
        path: "materiales_simce",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialesSIMCEComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "materiales_saber",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialesSaberComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "recursos_interactivos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RecursosInteractivosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "material_udd",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialUDDComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "material_app",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialAppsComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "biblioteca",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialesThumbnailsComponent, canActivate: [AuthGuard] }]
    }
]

export const materialesRouting = RouterModule.forChild(materialesRoutes)
