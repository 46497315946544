<!--<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse nav-justified-menu">
        <ul class="nav navbar-nav">
            <li *ngIf="showSimce" routerLinkActive="active-menu">
                <a [routerLink]="['/simce']" role="button">
                    <ogr-icon name="prueba" align="top"></ogr-icon>
                    &nbsp;Evaluaciones SIMCE
                </a>
            </li>
            <li *ngIf="showPruebas" routerLinkActive="active-menu">
                <a [routerLink]="['/pruebas']" role="button">
                    <ogr-icon name="guia" align="top"></ogr-icon>
                    &nbsp;Pruebas curriculares
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="[planClasesRoute]" role="button">
                    <ogr-icon name="editar-prueba" align="top"></ogr-icon>
                    <span>Estudiar</span>
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/streaming']" role="button">
                    <ogr-icon name="video" align="top"></ogr-icon>
                    &nbsp;Videos y clases
                </a>
                <i checkStreamingOnline name="camara-video" class="live">
                    <ogr-icon name="camara-video" class="icono"></ogr-icon>
                    en vivo
                </i>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/materiales']" [queryParams]="{ asignatura_id: asignaturaId }" role="button">
                    <ogr-icon name="libro2-o" align="top"></ogr-icon>
                    &nbsp;Biblioteca
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/recursos_interactivos']" role="button">
                    <ogr-icon name="amor-y-paz" class="icono" align="top"></ogr-icon>
                    &nbsp;Recursos interactivos
                </a>
            </li>
        </ul>
    </div>
</nav>-->
<nav class="topmenu" #topmenu>
    <div class="overflow-menu-container">
        <div class="menu-options-container">
            <ul class="menu-options">
                <ng-container *ngFor="let item of itemsMenu">
                    <li *ngIf="item.active" routerLinkActive="active-menu">
                        <a [routerLink]="item.route" [queryParams]="item.queryParams" role="button">
                            <ogr-icon class="icono" name="{{ item.icon }}" align="top"></ogr-icon>
                            &nbsp;{{ item.name }}
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</nav>
