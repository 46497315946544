import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"

import { HomeComponent } from "./home.component"
import { LandingComponent } from "./landing.component"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { SharedLayoutsModule } from "@puntaje/platforms/alcl"
import { LandingRouting } from "./alumnos_landing.routing"
import { FAQComponent } from "./faq.component"
import { AcercaDeComponent } from "./acerca-de/acerca-de.component"

import { LandingModule } from "@puntaje/puntaje/new-modules/landing"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { NoticiasModule } from "@puntaje/puntaje/new-modules/noticias"
import { StreamingModule } from "@puntaje/puntaje/new-modules/streaming"
import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { PuntajeLandingModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        LandingRouting,
        AlumnosLayoutsModule,
        LandingModule,
        UsuariosModule,
        UtilModule,
        Angular2FontawesomeModule,
        SharedLayoutsModule,
        MaterialesModule,
        NotificacionesModule,
        EnsayosModule,
        StreamingModule,
        NoticiasModule,
        CUILayoutsModule,
        PuntajeLandingModule
    ],
    declarations: [HomeComponent, LandingComponent, FAQComponent, AcercaDeComponent],
    exports: [HomeComponent, LandingComponent, FAQComponent, AcercaDeComponent]
})
export class AlumnosLandingModule {
    static forRoot(): ModuleWithProviders<AlumnosLandingModule> {
        return {
            ngModule: AlumnosLandingModule,
            providers: []
        }
    }
}
