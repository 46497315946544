import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { AyudaComponent, AyudasComponent } from "@puntaje/puntaje/core"

export const alumnosAyudaRoutes: Routes = [
    {
        path: "ayudas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: AyudasComponent,
                canActivate: [AuthGuard],
                data: { targetApp: "alumnos", titulo: "Material de ayuda para Estudiantes" }
            }
        ]
    },
    {
        path: "ayudas/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AyudaComponent, canActivate: [AuthGuard] }]
    }
]

export const alumnosAyudaRouting = RouterModule.forChild(alumnosAyudaRoutes)
