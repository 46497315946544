import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home_simce.component.html",
    styleUrls: ["home_simce.component.scss"]
})
export class HomeSimceComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()
        this.menuItems = [
            {
                route: "/simce/resultados/",
                params: { tipo_instrumento: "simce", asignatura_id: 1 },
                label: "Historial SIMCE",
                text: "Encuentra aquí la lista de todas las evaluaciones SIMCE que has realizado.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/simce/compartidas/",
                params: { tipo_instrumento: "simce", asignatura_id: 1 },
                label: "Historial de ensayos SIMCE compartidos",
                text: "Encuentra aquí la lista de todas las evaluaciones SIMCE que te han compartido.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/simce/mi_progreso/",
                params: { tipo_instrumento: "simce" },
                label: "Mi Progreso SIMCE",
                text: "Observa la evolución en el tiempo de tus resultados obtenidos.",
                linkText: "Ver mi progreso",
                icon: "estadistica-o"
            },
            {
                route: "/simce/estadisticas/",
                params: { tipo_instrumento: "simce" },
                label: "Estadísticas SIMCE",
                text: "Encuentra aquí estadísticas generadas a partir de tus resultados en evaluaciones SIMCE.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }
}
