<ll-titulo>Bienvenid@</ll-titulo>

<noticias-aviso-home></noticias-aviso-home>
<div class="row">
    <div class="col-md-8 col-sm-6">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <usuario-showcasebox></usuario-showcasebox>
                <br />
                <noticias-alumnos-showcasebox [defaultNotFound]="false"></noticias-alumnos-showcasebox>
                <br />
            </div>
            <div class="col-md-6 col-sm-12">
                <notificaciones-showcasebox [maximoNotificaciones]="3"></notificaciones-showcasebox>
                <br />
            </div>
        </div>
    </div>
    <div class="col-md-4 col-sm-6">
        <div class="destacados-content">
            <div class="destacados clearfix">
                <div class="icon-circle"><fa name="star"></fa></div>
                Destacados
            </div>

            <materiales-showcasebox evaluacionTipo="curricular"></materiales-showcasebox>
        </div>
        <br />
    </div>
</div>