import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "materiales-thumbnails.component.html",
    styleUrls: ["materiales-thumbnails.component.scss"]
})
export class MaterialesThumbnailsComponent implements OnInit, OnDestroy {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number = 1
    private sub: Subscription
    enableResponsive: boolean = false
    @ViewChild("innerContent", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(protected titleService: TitleService, protected route: ActivatedRoute, protected router: Router) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.materiales.materailes.thumbnails")
        this.sub = this.route.queryParams.subscribe(query => {
            this.asignatura = null
            this.asignaturaId = +query["asignatura_id"]
        })
        this.enableResponsive = window.innerWidth <= 900
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onReadyCurso(curso_id) {
        this.cursoId = curso_id
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
    }

    onChangeAsignatura(asignaturaId) {
        this.loadingLayout.standby()
        this.asignaturaId = asignaturaId
        this.loadingLayout.ready()
    }
}
