import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, ActivatedRoute } from "@angular/router"
import { SessionService } from "@puntaje/shared/core"
import { SimpleModalService } from "@puntaje/shared/layouts"
import {
    EvaluacionTiempos,
    EvaluacionUsuarios,
    EvaluacionInstancias,
    Evaluaciones
} from "@puntaje/puntaje/api-services"

@Injectable({ providedIn: "root" })
export class EvaluacionGuard implements CanActivate {
    constructor(
        private simpleModalService: SimpleModalService,
        private evaluacionTiemposService: EvaluacionTiempos,
        private evaluacionUsuariosService: EvaluacionUsuarios,
        private evaluacionInstanciasService: EvaluacionInstancias,
        private evaluacionesService: Evaluaciones,
        private sessionService: SessionService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<boolean>(async (resolve, reject) => {
            const usuario = this.sessionService.getUserData()
            const evaluacionId = route.params.id

            const evaluacionUsuarios = await this.evaluacionUsuariosService.where({
                evaluacion_usuario: { receptor_type: "GrupoUsuario", evaluacion_id: evaluacionId }
            })
            const nonNullReceptorIdEvaluacionUsuarios = evaluacionUsuarios.filter(eu => eu.receptor_id)

            if (nonNullReceptorIdEvaluacionUsuarios.length > 0) {
                const [evaluacionInstancias, evaluacionTiempos, evaluacion] = await Promise.all([
                    this.evaluacionInstanciasService.where({
                        evaluacion_instancia: { evaluacion_id: evaluacionId, usuario_id: usuario.id }
                    }),
                    this.evaluacionTiemposService.where({
                        evaluacion_tiempo: { evaluacion_id: evaluacionId, usuario_id: usuario.id }
                    }),
                    this.evaluacionesService.find(evaluacionId, { include: "instrumento" }).catch(() => null)
                ])

                if (evaluacion && evaluacionInstancias.length == 0 && evaluacionTiempos.length == 0) {
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Advertencia")
                    this.simpleModalService.setModalHTMLContent(`
                       Esta evaluación tiene ${evaluacion.instrumento.numero_preguntas} preguntas para ser desarrollada en ${evaluacion.instrumento.tiempo} minutos. <br /><br />
                       ¿Estás seguro/a de que quieres comenzar la evaluación? <br /><br />
                       Al ingresar comenzará a correr el tiempo y seguirá avanzando aunque salgas de la plataforma. Se guardará tu avance y podrás retomar la evaluación mientras estés dentro del tiempo. Una vez que este finalice, se calificará automáticamente con lo que hayas contestado. <br /><br />
                       Recuerda que el primer intento es el que se envía a tu establecimiento.<br /><br />
                       ¡Éxito!`)
                    this.simpleModalService.setModalCallback(() => resolve(true))
                    this.simpleModalService.cancelModalCallback(() => {
                        if (!this.route.firstChild) {
                            this.router.navigate(["/home"])
                        }

                        resolve(false)
                    })
                    this.simpleModalService.showSimpleModal()
                } else {
                    resolve(true)
                }
            } else {
                resolve(true)
            }
        })
    }
}
