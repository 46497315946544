<footer>
    <div class="footer-wrap">
        <div class="footer-row">
            <div class="footer-col">
                <h3>Estudia con {{ config.plataforma.info.companyName }}</h3>
                <ul>
                    <li>
                        <a
                            href="#section1"
                            easyScrollTo
                            [easyScrollToPadding]="0"
                            [easyScrollToDuration]="800"
                            [easyScrollToEasing]="'easeInOutExpo'"
                        >
                            Iniciar sesión | Registrarse
                        </a>
                    </li>
                    <li>
                        <a href="/preguntas_frecuentes">Preguntas frecuentes</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h3>Docentes</h3>
                <ul>
                    <li>
                        <a href="https://docentes.puntajenacional.cl/">Portal de docentes</a>
                    </li>
                </ul>
            </div>
            <div class="footer-col">
                <h3>Más información</h3>
                <ul>
                    <li>
                        <a routerLink="/condicionesUso">Términos y condiciones de uso</a>
                    </li>
                    <!--<li>
                        <a href="#section2">Políticas de privacidad</a>
                    </li>-->
                </ul>
            </div>
        </div>
    </div>
    <hr />
    <div class="footer-wrap">
        <div class="footer-row">
            <div class="footer-col">
                <div class="company-logo clearfix">
                    <a href="http://ogr.cl/">
                        <img
                            class="footer-logo"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                    <p>Todos los Derechos Reservados a "Open Green Road S.A." ®</p>
                </div>
            </div>
            <div class="footer-col">
                <div class="company-logo clearfix">
                    <p>En colaboración</p>
                    <div>
                        <img src="/assets/img/mineduc.png" alt="Logo del Ministerio de la Educación" />
                    </div>
                </div>
            </div>
            <div class="footer-col">
                <p class="follow">¡Síguenos!</p>
                <div class="socialMedia">
                    <a href="{{ config.plataforma.info.social.instagram }}" target="_blank" title="Instagram">
                        <fa name="instagram"></fa>
                    </a>
                    <a href="{{ config.plataforma.info.social.youtube }}" target="_blank" title="Youtube">
                        <fa name="youtube"></fa>
                    </a>
                    <a href="{{ config.plataforma.info.social.twitch }}" target="_blank" title="Twitch">
                        <fa name="twitch"></fa>
                    </a>
                    <a class="tiktok" href="{{ config.plataforma.info.social.tiktok }}" target="_blank" title="TikTok">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="tiktok"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-tiktok fa-w-14 fix-size"
                        >
                            <path
                                fill="currentColor"
                                d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                                class=""
                            ></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
