import { Component, HostBinding, OnDestroy } from "@angular/core"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { DomSanitizer } from "@angular/platform-browser"
import { AuthService, TabService, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { trigger, transition, style, animate, keyframes } from "@angular/animations"

/*#################################

Barra de navegación que va en la parte superior.

#################################*/

@Component({
    templateUrl: "navbar.component.html",
    selector: "navbar",
    styleUrls: ["navbar.component.scss"],
    animations: [
        trigger("topmenu", [
            transition(":enter", [
                animate(
                    "0.2s ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateX(-100px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
                    ])
                )
            ]),
            transition(":leave", [
                animate(
                    "0.2s ease-in",
                    keyframes([
                        style({ opacity: 1, transform: "translateX(0)", offset: 0 }),
                        style({ opacity: 0, transform: "translateX(-100px)", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class NavbarComponent implements OnDestroy {
    @HostBinding("class.profile1") useProfile1: boolean = false
    usuario_nombre: string
    usuario_apellido_paterno: string
    usuario_id: string
    usuario_avatar: string
    userUpdateSubscription: Subscription
    contactMail: string
    companyName: string
    showTopmenu: boolean = false

    config: typeof config

    constructor(
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected router: Router,
        protected sanitizer: DomSanitizer,
        protected tabService: TabService
    ) {
        this.useProfile1 = true
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : null
        this.contactMail = config.plataforma.info.contacto.mail
        this.companyName = config.plataforma.info.companyName
        this.userUpdateSubscription = this.sessionService.userDataUpdated.subscribe(usuario => {
            this.updateUserData(usuario)
        })

        this.config = config
    }

    logout() {
        this.authService.logout()
        let host = window.location.hostname
        var new_host = host
        // Redirect the user
        if (environment.production) {
            new_host = host.replace(/^([a-z]+)\./g, "www.")
            window.location.href = "http://" + new_host
        } else {
            this.router.navigate(["/landing"])
        }
    }

    edit() {
        if (this.usuario_id != null) {
            this.router.navigate(["/usuarios/editar"])
        }
    }

    goToProfile() {
        if (this.usuario_id != null) {
            this.tabService.setComponentTab("UsuarioComponent", "perfil")
            this.router.navigate(["/usuarios/" + this.usuario_id])
        }
    }

    goToAyuda() {
        this.router.navigate(["/ayudas"])
    }

    goToAchievements() {
        if (this.usuario_id != null) {
            this.tabService.setComponentTab("UsuarioComponent", "logros")
            this.router.navigate(["/usuarios/" + this.usuario_id])
        }
    }

    sanitize(input: string): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(input)
    }

    updateUserData(usuario) {
        //usuario = this.authService.getUserData();
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : null
    }

    ngOnDestroy() {
        this.userUpdateSubscription.unsubscribe()
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    toggleTopmenu() {
        this.showTopmenu = !this.showTopmenu
    }
}
