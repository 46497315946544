import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { ClasePlanClaseComponent, PlanClaseAlumnoComponent, PlanClasesDeAlumnosComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "planes_estudio",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClasesDeAlumnosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "asignatura/:asignatura_id/planes_estudio",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClasesDeAlumnosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_estudios/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard],
                data: { planUrl: "/plan_estudios", isLibroFrom: "alumno", enableDudas: false }
            }
        ]
    },
    {
        path: "plan_estudios/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseAlumnoComponent,
                canActivate: [AuthGuard],
                data: { baseRoute: "/plan_estudios" }
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
