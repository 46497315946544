import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { ApiModule } from "@puntaje/puntaje/api-services"
import { routing } from "./alumnos_ensayos.routing"
import { UtilModule, TranslationsModule } from "@puntaje/shared/core"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { HomeSimceComponent } from "./home_simce.component"
import { HomePruebasComponent } from "./home_pruebas.component"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { CUIContainersModule, CUIInputsModule } from "@puntaje/shared/cui"
import { PuntajeEnsayosModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        AlumnosLayoutsModule,
        routing,
        Angular2FontawesomeModule,
        UtilModule,
        SharedModule,
        NebuModule,
        TranslationsModule,
        CUIContainersModule,
        CUILayoutsModule,
        PuntajeEnsayosModule
    ],
    declarations: [HomePruebasComponent, HomeSimceComponent],
    exports: []
})
export class AlumnosEnsayosModule {}
