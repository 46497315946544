import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { HomeSimceComponent } from "./home_simce.component"
import { HomePruebasComponent } from "./home_pruebas.component"
import { EvaluacionGuard } from "@puntaje/puntaje/services"
import {
    EnsayoInstanciasComponent,
    EnsayosMensualesComponent,
    EstadisticasAlumnoComponent,
    EvaluacionesCorregirComponent,
    GenerarEnsayoComponent,
    ProgresoAlumnoComponent,
    RealizarEnsayoComponent,
    ResultadosEnsayoComponent
} from "@puntaje/puntaje/core"
import { EvaluacionesCompartidasComponent } from "@puntaje/puntaje/new-modules/ensayos"

export const routes: Routes = [
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [AuthGuard],
                data: { attrPresencial: false }
            }
        ]
    },
    {
        path: "ensayos/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: RealizarEnsayoComponent,
                canActivate: [AuthGuard, EvaluacionGuard],
                data: { enableEntregarGeneric: true, disableWarningOmitidas: true, disableOmitida: true }
            }
        ]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: ResultadosEnsayoComponent,
                canActivate: [AuthGuard],
                data: { enableDudas: false, porcentaje: false }
            }
        ]
    },
    {
        path: "evaluacion_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [
            { path: "", component: ResultadosEnsayoComponent, canActivate: [AuthGuard], data: { enableDudas: false } }
        ]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: RealizarEnsayoComponent,
                canActivate: [AuthGuard, EvaluacionGuard],
                data: { enableEntregarGeneric: true, disableWarningOmitidas: true, disableOmitida: true }
            }
        ]
    },
    {
        path: "evaluaciones/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "evaluaciones/:id/corregir",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionesCorregirComponent,
                canActivate: [AuthGuard],
                data: { typePlatform: "alumnoOne", titulo: "Corregir Evaluación" }
            }
        ]
    },
    {
        path: "ensayos_breves",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayosMensualesComponent, canActivate: [AuthGuard] }]
    },
    //Calugas
    {
        path: "simce/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasAlumnoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "simce/mi_progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoAlumnoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "simce/resultados",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [AuthGuard],
                data: { attrPresencial: false }
            }
        ]
    },
    {
        path: "simce/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "simce",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeSimceComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "pruebas/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasAlumnoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "pruebas/mi_progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoAlumnoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [AuthGuard],
                data: { attrPresencial: false }
            }
        ]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebasComponent, canActivate: [AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
