import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { StreamingComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "streaming",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: StreamingComponent, canActivate: [AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
